import React from "react";
import Wrapper from './Wrapper.tsx';

export default function HomePage() {
  return (
    <Wrapper>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="container-fluid">

            <div className="row">

              <div className="col-lg-6">

                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Pets Quality of Life</h6>
                  </div>
                  <div className="card-body">
                    <img className="pop-img" src="img/MyPet_Behavior.jpg" alt="Behavior"/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Human Animal Bond</h6>
                  </div>
                  <div className="card-body">
                    <img className="pop-img" src="img/MyPet_Lifestyle.jpg" alt="Lifestyle" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}