import React from 'react';
import TopMenu from './TopMenu.tsx';

export default function Wrapper({children}) {
    return (

                <div>
                    <TopMenu />                    
                    {/* Topbar */}
                    {children}

                </div>

    );
}

