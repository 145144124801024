import React from "react";

export default function TopMenu() {
  return (
    <div className="menubar">
      <a className="active" href="/"><i className="fa fa-fw fa-home"></i> Home</a>
      <a href="/petownerlogin.html"><i className="fa fa-fw fa-user"></i> Pet Owner</a>
      <a href="/vetlogin.html"><i className="fa fa-fw fa-user"></i> Vet</a>
      <a href="/stafflogin"><i className="fa fa-fw fa-user"></i> Staff</a>            
    </div>
  );
}


