import './App.css';
import { HP } from '../src/scripts/HP';

function App() {

  return (
    <div className="App">
      <HP />
    </div>
  );
}

export default App;
